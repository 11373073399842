import React, { useEffect, useMemo, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { arrowRight } from "../../../../assets/svg/Svg";
import { Col, Modal, ModalBody, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { changeQrStatus, getQrDetail } from "../../../../redux/slices/qrSlice";
import { Funnel } from "recharts";

const IMG_URL = process.env.REACT_APP_AWS_IMAGE_URL;

const QrDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const { id } = useParams();
  const { qrDetails, refetch } = useSelector((state) => state.qrSlice);
  const prevProps = useRef({ refetch }).current;

  useEffect(() => {
    if (prevProps.refetch !== refetch) {
      setLoading(true);
      dispatch(getQrDetail(id, setLoading));
    }
    return () => {
      prevProps.refetch = refetch;
    };
  }, [dispatch, prevProps, refetch, id]);

  useEffect(() => {
    setLoading(true);
    dispatch(getQrDetail(id, setLoading));
  }, [dispatch, id]);

  useEffect(() => {
    if (qrDetails && qrDetails?.memberDetails?.length > 0) {
      setData(qrDetails?.memberDetails);
    } else {
      setData([]);
    }
  }, [qrDetails]);

  const columns = [
    {
      name: "Created At",
      selector: (row) =>
        new Date(row.createdAt)
          .toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          })
          .replace(/\//g, "-"),
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => (
        <div
          style={{ color: "#145f33", fontWeight: "bold" }}
          onClick={() => navigate(`/user/${row?._id}`)}
        >
          {row?.name}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      sortable: true,
    },
    {
      name: "Contact Number",
      selector: (row) => row?.mobileNumber,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <label className={`badge badge-${row.active ? "success" : "danger"}`}>
          {row.active ? "Active" : "Inactive"}
        </label>
      ),
      sortable: true,
    },
  ];

  const filteredData = useMemo(() => {
    return data.filter((item) => {
      const matchesSearchText = item?.name
        .toLowerCase()
        .includes(searchText.toLowerCase());

      const matchesStatus =
        status === "Active"
          ? item.active === true
          : status === "Inactive"
          ? item.active === false
          : true;

      return matchesSearchText && matchesStatus;
    });
  }, [data, searchText, status]);

  const handleSearch = (value) => {
    setSearchText(value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const handleStatusChange = (value) => {
    setStatus(value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    noRowsPerPage: false,
    selectAllRowsItem: false,
    selectAllRowsItemText: "All",
  };

  const customPagination = () => {
    const page = Math.ceil(filteredData.length / paginationPerPage);
    const pageButtons = [];
    const startPage = Math.max(currentPage - 1, 1);
    const endPage = Math.min(currentPage + 1, page);

    // Always add the first page
    pageButtons.push(
      <button
        key={1}
        className={`btn-pagination ${currentPage === 1 ? "active" : ""}`}
        onClick={() => setCurrentPage(1)}
      >
        1
      </button>
    );

    // Ellipsis after the first page if necessary
    if (startPage > 2) {
      pageButtons.push(
        <span key="ellipsis-start" className="pagination-ellipsis">
          &hellip;
        </span>
      );
    }

    // Range of buttons around the current page
    for (let i = startPage; i <= endPage; i++) {
      if (i !== 1 && i !== page) {
        pageButtons.push(
          <button
            key={i}
            className={`btn-pagination ${currentPage === i ? "active" : ""}`}
            onClick={() => setCurrentPage(i)}
          >
            {i}
          </button>
        );
      }
    }

    // Ellipsis before the last page if necessary
    if (endPage < page - 1) {
      pageButtons.push(
        <span key="ellipsis-end" className="pagination-ellipsis">
          &hellip;
        </span>
      );
    }

    // Always add the last page
    if (page !== 1) {
      pageButtons.push(
        <button
          key={page}
          className={`btn-pagination ${currentPage === page ? "active" : ""}`}
          onClick={() => setCurrentPage(page)}
        >
          {page}
        </button>
      );
    }

    return (
      <div className="pagination-container mt-5">
        <button
          className="btn-pagination btn-pagi_prev"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {arrowRight}
        </button>
        <span>{pageButtons}</span>
        <button
          className="btn-pagination btn-pagi_next"
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === page}
        >
          {arrowRight}
        </button>
      </div>
    );
  };

  const paginationPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const paginatedData = filteredData.slice(
    (currentPage - 1) * paginationPerPage,
    currentPage * paginationPerPage
  );

  const handleChangeStatus = () => {
    console.log("here");
    setLoading(true);
    dispatch(changeQrStatus(id, setLoading, setModalShow));
  };

  return (
    <>
      {loading ? (
        <div className="w-100 d-flex">
          <div class="spinner-border text-primary mx-auto" role="status"></div>
        </div>
      ) : (
        <>
          <div>
            <div className="row">
              <div className="col-lg-5 mx-auto grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <Row>
                      <Col className="mx-auto" md={10}>
                        <Row>
                          <Col md={12}>
                            <div class="my-3 input_dark mx-auto">
                              <label
                                for="exampleInputEmail1"
                                class="form-label w-100 mb-0"
                              >
                                <span className="d-flex w-100 align-items-center gap-3">
                                  Status :{" "}
                                  <div
                                    onClick={() => setModalShow(true)}
                                    className="checkbox ms-auto me-2"
                                  >
                                    <input
                                      type="checkbox"
                                      id="cbx11"
                                      checked={qrDetails?.active}
                                      className="d-none toggle_switch"
                                    />
                                    <label
                                      for="cbx11"
                                      className="toggle mb-0 mt-1"
                                    >
                                      <span></span>
                                    </label>
                                  </div>
                                </span>
                              </label>
                            </div>
                          </Col>
                          <Col md={12}>
                            <div class="mb-3 input_dark">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                QR Image
                              </label>
                              <div>
                                <img
                                  className="w-100"
                                  src={`${
                                    qrDetails.qrImage
                                      ? `${IMG_URL}${qrDetails.qrImage}`
                                      : "https://picsum.photos/200"
                                  }`}
                                  alt="Productimage"
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={12}>
                            <div class="mb-3 input_dark">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                QR Title
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                value={qrDetails?.name}
                                placeholder="Product Title"
                                aria-describedby="emailHelp"
                                readOnly
                              />
                            </div>
                          </Col>
                          {qrDetails?.type == "hotel" && (
                            <Col md={12}>
                              <div class="mb-3 input_dark">
                                <label
                                  for="exampleInputEmail1"
                                  class="form-label"
                                >
                                  Hotel Name
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="exampleInputEmail1"
                                  value={qrDetails?.hotelName}
                                  placeholder="Product Title"
                                  aria-describedby="emailHelp"
                                  readOnly
                                />
                              </div>
                            </Col>
                          )}
                          {qrDetails?.type == "home" && (
                            <Col md={12}>
                              <div class="mb-3 input_dark">
                                <label
                                  for="exampleInputEmail1"
                                  class="form-label"
                                >
                                  Contact Number
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="exampleInputEmail1"
                                  value={
                                    qrDetails?.contactNumber || "Not Updated"
                                  }
                                  placeholder="Contact Number"
                                  aria-describedby="emailHelp"
                                  readOnly
                                />
                              </div>
                            </Col>
                          )}
                          {qrDetails?.type == "hotel" && (
                            <Col md={12}>
                              <div class="mb-3 input_dark">
                                <label
                                  for="exampleInputEmail1"
                                  class="form-label"
                                >
                                  Hotel Contact Number
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="exampleInputEmail1"
                                  value={qrDetails?.hotelContactNumber}
                                  placeholder="Product Title"
                                  aria-describedby="emailHelp"
                                  readOnly
                                />
                              </div>
                            </Col>
                          )}
                          {qrDetails?.type == "car" && (
                            <Col md={12}>
                              <div class="mb-3 input_dark">
                                <label
                                  for="exampleInputEmail1"
                                  class="form-label"
                                >
                                  Vehicle Number
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="exampleInputEmail1"
                                  value={qrDetails?.carNumber}
                                  placeholder="Product Title"
                                  aria-describedby="emailHelp"
                                  readOnly
                                />
                              </div>
                            </Col>
                          )}
                          {qrDetails?.type !== "car" && (
                            <>
                              <Col md={12}>
                                <div class="mb-3 input_dark">
                                  <label
                                    for="exampleInputEmail1"
                                    class="form-label"
                                  >
                                    Address
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    value={qrDetails?.address}
                                    placeholder="QR Address"
                                    aria-describedby="emailHelp"
                                    readOnly
                                  />
                                </div>
                              </Col>
                              <Col md={12}>
                                <div class="mb-3 input_dark">
                                  <label
                                    for="exampleInputEmail1"
                                    class="form-label"
                                  >
                                    State
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    value={qrDetails?.state}
                                    placeholder="QR Address State"
                                    aria-describedby="emailHelp"
                                    readOnly
                                  />
                                </div>
                              </Col>
                              <Col md={12}>
                                <div class="mb-3 input_dark">
                                  <label
                                    for="exampleInputEmail1"
                                    class="form-label"
                                  >
                                    City
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    value={qrDetails?.city}
                                    placeholder="QR Address City"
                                    aria-describedby="emailHelp"
                                    readOnly
                                  />
                                </div>
                              </Col>
                              <Col md={12}>
                                <div class="mb-3 input_dark">
                                  <label
                                    for="exampleInputEmail1"
                                    class="form-label"
                                  >
                                    Pincode
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    value={qrDetails?.pincode}
                                    placeholder="QR Address Pincode"
                                    aria-describedby="emailHelp"
                                    readOnly
                                  />
                                </div>
                              </Col>
                            </>
                          )}
                          <Col md={12}>
                            <div class="mb-3 input_dark">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                QR Owner
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                value={qrDetails?.userDetails?.[0]?.name}
                                placeholder="Product Title"
                                aria-describedby="emailHelp"
                                readOnly
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Modal
                      show={modalShow}
                      size="sx"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <ModalBody>
                        <h4>Sure want to change status!</h4>
                      </ModalBody>
                      <Modal.Footer>
                        <button
                          type="button"
                          className="btn_secondary"
                          onClick={() => setModalShow(false)}
                        >
                          Not now
                        </button>
                        <button
                          type="button"
                          className="btn_primary"
                          onClick={handleChangeStatus}
                        >
                          Yes!
                        </button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {data.length > 0 && (
            <div className="mt-4">
              <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <>
                        <DataTable
                          title={<h6 className="card-title">Members List</h6>}
                          columns={columns}
                          data={paginatedData}
                          pagination
                          paginationServer
                          paginationTotalRows={filteredData.length}
                          onChangePage={(page) => setCurrentPage(page)}
                          paginationComponentOptions={paginationOptions}
                          paginationComponent={customPagination}
                          highlightOnHover
                          pointerOnHover
                          subHeader
                          subHeaderAlign="left"
                          subHeaderComponent={
                            <>
                              <Row className="mb-3 w-100">
                                <Col
                                  md={8}
                                  className="d-flex align-items-start flex-column gap-2"
                                >
                                  <span className="d-flex align-items-center gap-2 me-3 filterby_label">
                                    <Funnel />
                                    Filter By :
                                  </span>
                                  <div className="table_filter">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search by Title"
                                      value={searchText}
                                      onChange={(e) =>
                                        handleSearch(e.target.value)
                                      }
                                    />
                                    <select
                                      className="form-control h-54px w-auto"
                                      value={status}
                                      onChange={(e) =>
                                        handleStatusChange(e.target.value)
                                      }
                                    >
                                      <option selected value="">
                                        Status
                                      </option>
                                      <option value="Active">Active</option>
                                      <option value="Inactive">Inactive</option>
                                    </select>
                                  </div>
                                </Col>
                              </Row>
                            </>
                          }
                        />
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default QrDetails;
