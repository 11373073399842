import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    loading: false,
    dashboard: {},
  },
  reducers: {
    getDashboardSuccess: (state, action) => {
      state.dashboard = action.payload;
    },
  },
});

export const { getDashboardSuccess } = dashboardSlice.actions;


export const getDashboardResponse = (setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/dashboard/get/v1`;
    const res = await axios.get(url, {
      headers: { Authorization: localStorage.getItem("token") },
    });
    dispatch(getDashboardSuccess(res.data.data));
    setLoading(false);
  } catch (error) {
    console.log(error);
    if(error.response.data.status==(401 || 403 )){
      Navigate("/login")
    }
    toast.error(error.response.data.message, {
      position: "top-center",
      autoClose: 5000,
    });
    setLoading(false);
  }
};

export default dashboardSlice.reducer;
