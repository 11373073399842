import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import NavLink from "../components/NavLink";
import {
  ChartLine,
  Users,
  QrCode,
  ShoppingBag,
  QuestionMark,
  Barcode,
  CurrencyInr,
  Money,
  BoxArrowDown,
  ReadCvLogo,
  Key,
  PhoneCall,
} from "@phosphor-icons/react";
import { IoSettingsOutline, IoHelp,IoChatbox } from "react-icons/io5";

const sidebarData = [
  {
    commonUrl: "/",
    primary: {
      iconClass: <ChartLine size={18} />,
      title: "Dashboard",
      url: "/",
    },
  },
  {
    commonUrl: "/users",
    primary: {
      iconClass: <Users size={18} />,
      title: "Users",
      url: "/users",
    },
  },
  {
    commonUrl: "/products",
    primary: {
      iconClass: <Barcode size={18} />,
      title: "Mobile-Products",
      url: "/products",
    },
  },
  {
    commonUrl: "/web-products",
    primary: {
      iconClass: <BoxArrowDown size={18} />,
      title: "Web-Products",
      url: "/web-products",
    },
  },
  {
    commonUrl: "/orders",
    primary: {
      iconClass: <ShoppingBag size={18} />,
      title: "QR-Orders",
      url: "/orders",
    },
  },
  {
    commonUrl: "/product-orders",
    primary: {
      iconClass: <ShoppingBag size={18} />,
      title: "Product-Orders",
      url: "/product-orders",
    },
  },
  {
    commonUrl: "/qrs",
    primary: {
      iconClass: <QrCode size={18} />,
      title: "QR",
      url: "/qrs",
    },
  },
  {
    commonUrl: "/subscriptions",
    primary: {
      iconClass: <Money size={18} />,
      title: "Subscription-Plans",
      url: "/subscriptions",
    },
  },
  {
    commonUrl: "/vouchers",
    primary: {
      iconClass: <ReadCvLogo size={18} />,
      title: "Vouchers",
      url: "/vouchers",
    },
  },
  {
    commonUrl: "/payments",
    primary: {
      iconClass: <CurrencyInr size={18} />,
      title: "Payments",
      url: "/payments",
    },
  },
  {
    commonUrl: "/call-logs",
    primary: {
      iconClass: <PhoneCall size={18} />,
      title: "Call-Logs",
      url: "/call-logs",
    },
  },
  {
    commonUrl: "/contacts-us",
    primary: {
      iconClass: <IoChatbox size={18} />,
      title: "Contact-Us",
      url: "/contacts-us",
    },
  },
  {
    commonUrl: "/change_password",
    primary: {
      iconClass: <Key size={18} />,
      title: "Change Password",
      url: "/change_password",
    },
  },
  {
    commonUrl: "/faqs",
    primary: {
      iconClass: <IoHelp size={18} />,
      title: "FAQs",
      url: "/faqs",
    },
  },
  {
    commonUrl: "/settings",
    primary: {
      iconClass: <IoSettingsOutline size={18} />,
      title: "App-Settings",
      url: "/settings",
    },
  },
];

const Sidebar = () => {
  const location = useLocation();
  const [menuState, setMenuState] = useState({});
  const [bodyClass, setBodyClass] = useState("sidebar-icon-only");

  const toggleMenuState = (menuStateKey) => {
    setMenuState((prevState) => ({
      ...Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {}),
      [menuStateKey]: !prevState[menuStateKey],
    }));
  };

  const onRouteChanged = () => {
    document.querySelector("#sidebar").classList.remove("active");
    setMenuState({});
  };

  const isPathActive = (path) => {
    console.log(path, "path");
    console.log(location.pathname, "location.pathname");
    return location.pathname === path;
  };

  useEffect(() => {
    onRouteChanged();
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", () => {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", () => {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });

    return () => {
      // Clean up event listeners if needed
    };
  }, [location]);

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        {sidebarData.map((data) => {
          return (
            <NavLink
              isPathActive={isPathActive}
              url={data.primary.url}
              iconClass={data.primary.iconClass}
              title={data.primary.title}
              commonUrl={data.commonUrl}
              child={data.secondary}
            />
          );
        })}
      </ul>
    </nav>
  );
};

export default Sidebar;
