import React, { useEffect, useRef, useState } from "react";
import { FormGroup, Modal, ModalBody } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { createVoucher } from "../../../redux/slices/voucherSlice";

const AddVoucherModal = ({ modalShow, setModalShow }) => {
  const [formData, setFormData] = useState({
    voucherCode: "",
    discount: null,
    expiryDate: null,
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const fieldLabels = {
    voucherCode: "Voucher Code",
    discount: "Discount",
    expiryDate: "Expiry Date",
  };

  const handleSubmitForm = async () => {
    const newErrors = {};

    // Validate only name and price fields
    if (!formData.voucherCode) {
      newErrors.voucherCode = `${fieldLabels.voucherCode} is required`;
    }
    if (!formData.discount) {
      newErrors.discount = `${fieldLabels.discount} is required`;
    }
    if (!formData.expiryDate) {
      newErrors.expiryDate = `${fieldLabels.expiryDate} is required`;
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      // Submit the form data
      dispatch(createVoucher(formData, setLoading, setModalShow, setFormData));
    }
  };

  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  return (
    <Modal
      show={modalShow}
      size="sx"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalBody>
        <h5 className="mb-3">Add New Subscription Plan</h5>

        <FormGroup className="mb-3">
          <label>Voucher Code </label>
          <input
            type="text"
            placeholder="Enter Voucher Code Name"
            value={formData.voucherCode}
            className="form-control"
            onChange={(e) =>
              setFormData({ ...formData, voucherCode: e.target.value })
            }
          />
        </FormGroup>
        {errors.voucherCode && (
          <div className="text-danger">{errors.voucherCode}</div>
        )}
        <FormGroup className="mb-3">
          <label>Discount (%)</label>
          <input
            type="number"
            placeholder="Enter Price"
            value={formData.discount}
            className="form-control"
            onChange={(e) =>
              setFormData({ ...formData, discount: e.target.value })
            }
          />
        </FormGroup>
        {errors.discount && (
          <div className="text-danger">{errors.discount}</div>
        )}
        <FormGroup className="mb-3">
          <label>Expiry Date </label>
          <input
            type="date"
            placeholder="Enter Price"
            value={formData.expiryDate}
            className="form-control"
            min={getTodayDate()}
            onChange={(e) =>
              setFormData({ ...formData, expiryDate: e.target.value })
            }
          />
        </FormGroup>
        {errors.expiryDate && (
          <div className="text-danger">{errors.expiryDate}</div>
        )}
      </ModalBody>
      <Modal.Footer>
        <button
          type="button"
          className="btn_secondary"
          onClick={() => {
            setModalShow(false);
            setErrors({});
            setFormData({
              voucherCode: "",
              discount: null,
              expiryDate: null,
            });
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn_primary"
          onClick={handleSubmitForm}
        >
          Add
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddVoucherModal;
