import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const callSlice = createSlice({
  name: "calls",
  initialState: {
    calls: [],
    refetch: true,
  },
  reducers: {
    getAllCallLogsListSuccess: (state, action) => {
      state.calls = action.payload;
    },
    refetch: (state, action) => {
      state.refetch = action.payload;
    },
  },
});

export const { getAllCallLogsListSuccess, refetch } = callSlice.actions;

export const getCallLogsList = (setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/call/list/v1`;

    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });

    dispatch(getAllCallLogsListSuccess(data.data));
    setLoading(false);
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};

export default callSlice.reducer;
