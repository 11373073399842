import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const faqSlice = createSlice({
  name: "faq",
  initialState: {
    faqs: [],
    refetch: false,
    faq: {},
  },
  reducers: {
    getAllGetFAQsSuccess: (state, action) => {
      state.faqs = action.payload;
    },
    refetch: (state, action) => {
      state.refetch = action.payload;
    },
    getFaqDetailSuccess: (state, action) => {
      state.faq = action.payload;
    },
  },
});

export const { getAllGetFAQsSuccess, refetch, getFaqDetailSuccess } =
  faqSlice.actions;

export const getAllFaqLists = (setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/faq/admin/list/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });

    dispatch(getAllGetFAQsSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error, "error");
    setIsLoading(false);
  }
};

export const getFaqDetails = (id, setLoading) => async (dispatch) => {
  try {
    let url = `${process.env.REACT_APP_API_BASE_URL}/faq/details/${id}/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });

    dispatch(getFaqDetailSuccess(data.data));
    setLoading(false);
  } catch (error) {
    console.log(error);
    toast.success("Something Went Wrong!!", {
      position: "top-center",
      autoClose: 5000,
    });
  }
};

export const createHelpAndFaq =
  (formData, setIsLoading, setFormData) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/faq/create/v1`;
      const { data } = await axios.post(url, formData, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      setFormData({
        question: "",
        answer: "",
      });
      dispatch(refetch(true));
      setIsLoading(false);
      toast.success("FAQ Created Successfully", {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong!", {
        position: "top-center",
        autoClose: 5000,
      });
      setIsLoading(false);
    }
  };

export const updateHelpAndFaq =
  (id, formData, setIsLoading, setFormData) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/faq/update/${id}/v1`;
      const { data } = await axios.put(url, formData, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      setFormData({
        question: "",
        answer: "",
      });
      setIsLoading(false);
      dispatch(refetch(true));
      toast.success("FAQ Updated Successfully", {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong!", {
        position: "top-center",
        autoClose: 5000,
      });
      setIsLoading(false);
    }
  };

export const deleteFaq =
  (id, setLoading, setDeleteModal, setId) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/faq/delete/${id}/v1`;
      const { data } = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      toast.success("Deleted Successfully", {
        position: "top-center",
        autoClose: 5000,
      });
      setLoading(false);
      setDeleteModal(false);
      setId("");
      dispatch(refetch(true));
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong!", {
        position: "top-center",
        autoClose: 5000,
      });
      setLoading(false);
      setDeleteModal(false);
      setId("");
    }
  };

export default faqSlice.reducer;
