import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

const voucherSlice = createSlice({
  name: "voucher",
  initialState: {
    vouchers: [],
    voucherDetails: {},
    refetch: false,
  },
  reducers: {
    getAllVouchersSuccess: (state, action) => {
      state.vouchers = action.payload;
    },
    getVoucherrDetailSuccess: (state, action) => {
      state.voucherDetails = action.payload;
    },
    refetch: (state, action) => {
      state.refetch = action.payload;
    },
  },
});

export const { getAllVouchersSuccess, getVoucherrDetailSuccess, refetch } =
  voucherSlice.actions;

export const getAllVouchers = (setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/voucher/list/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    dispatch(getAllVouchersSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    if (error.response.data.status == (401 || 403)) {
      Navigate("/login");
    }
    setIsLoading(false);
  }
};

export const getVoucherDetail = (id, setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/voucher/details/${id}/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    dispatch(getVoucherrDetailSuccess(data.data));
    setLoading(false);
  } catch (error) {
    console.log(error);
    if (error.response.data.status == (401 || 403)) {
      Navigate("/login");
    }
    setLoading(false);
  }
};

export const createVoucher =
  (formData, setLoading, setModalShow, setFormData) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/voucher/create/v1`;
      const { data } = await axios.post(url, formData, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      setLoading(false);
      dispatch(refetch(true));
      setModalShow(false);
      setFormData({
        voucherCode: "",
        discount: null,
        expiryDate: null,
      });
    } catch (error) {
      console.log(error);
      if (error.response.data.status == (401 || 403)) {
        Navigate("/login");
      }
      setLoading(false);
    }
  };

export const deleteVoucher =
  (id, setLoading, setDeleteModal) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/voucher/delete/${id}/v1`;
      const { data } = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch(refetch(true));
      setDeleteModal(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error.response.data.status == (401 || 403)) {
        Navigate("/login");
      }
      setLoading(false);
    }
  };

export const changeVoucherStatus =
  (id, setLoading, setModalShow) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/voucher/change/status/${id}/v1`;
      const { data } = await axios.put(
        url,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch(refetch(true));
      setModalShow(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error.response.data.status == (401 || 403)) {
        Navigate("/login");
      }
      setLoading(false);
    }
  };

export default voucherSlice.reducer;
