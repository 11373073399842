import React, { useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { deleteProduct } from "../../../redux/slices/productSlice";
import { deleteContactUs } from "../../../redux/slices/contactusSlice";
import { deleteQr } from "../../../redux/slices/qrSlice";
import { deleteSubscription } from "../../../redux/slices/subscriptionSlice";
import { deleteVoucher } from "../../../redux/slices/voucherSlice";
import { deleteWebProduct } from "../../../redux/slices/webProductSlice";
import { deleteFaq } from "../../../redux/slices/faqSlics";

const DeleteModal = ({
  forProduct,
  forWebProduct,
  forContactUs,
  forOrder,
  forSubscription,
  forVoucher,
  forQR,
  forFAQ,
  deleteModal,
  setDeleteModal,
  id,
  setId,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = () => {
    if (forProduct) {
      dispatch(deleteProduct(id, setIsLoading, setDeleteModal));
    } else if (forContactUs) {
      dispatch(deleteContactUs(id, setIsLoading, setDeleteModal));
    } else if (forQR) {
      dispatch(deleteQr(id, setIsLoading, setDeleteModal));
    } else if (forSubscription) {
      dispatch(deleteSubscription(id, setIsLoading, setDeleteModal));
    } else if (forVoucher) {
      dispatch(deleteVoucher(id, setIsLoading, setDeleteModal));
    } else if (forWebProduct) {
      dispatch(deleteWebProduct(id, setIsLoading, setDeleteModal));
    } else if (forFAQ) {
      dispatch(deleteFaq(id, setIsLoading, setDeleteModal,setId));
    }
  };

  return (
    <Modal
      show={deleteModal}
      size="sx"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalBody>
        <h5 className="mb-3">Are you sure you want to Delete ?</h5>
      </ModalBody>
      <Modal.Footer>
        <button
          type="button"
          className="btn_secondary"
          onClick={() => {
            setDeleteModal(false);
            setId("");
          }}
        >
          Cancel
        </button>
        <button type="button" className="btn_primary" onClick={handleDelete}>
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
