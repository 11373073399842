import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, ModalBody, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getPaymentDetails } from "../../../../redux/slices/paymentSlice";

const PaymentDetails = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const { paymentDetails, refetch } = useSelector(
    (state) => state.paymentSlice
  );

  const prevProps = useRef({ refetch }).current;

  useEffect(() => {
    if (prevProps.refetch != refetch) {
      setLoading(true);
      dispatch(getPaymentDetails(id, setLoading));
    }
    return () => {
      prevProps.refetch = refetch;
    };
  }, [dispatch, id, refetch, prevProps]);

  useEffect(() => {
    setLoading(true);
    dispatch(getPaymentDetails(id, setLoading));
  }, [dispatch, id]);

  return (
    <>
      {loading ? (
        <div className="w-100 d-flex">
          <div class="spinner-border text-primary mx-auto" role="status"></div>
        </div>
      ) : (
        <div>
          <div className="page-header">
            <h3 className="page-title">Payment Details</h3>
          </div>
          <Row className="d-flex justify-content-center">
            <Col md={5} className="">
              <div className="card">
                <div className="card-body">
                  <Row>
                    <Col md={12} className="profile_info px-4">
                      <div>
                        <span>Payment Date :</span>
                        <span>
                          {new Date(paymentDetails.createdAt)
                            .toLocaleDateString("en-US", {
                              month: "2-digit",
                              day: "2-digit",
                              year: "numeric",
                            })
                            .replace(/\//g, "-")}
                        </span>
                      </div>
                      <div>
                        <span>PaymentId :</span>
                        <span>{paymentDetails._id}</span>
                      </div>
                      <div>
                        <span>Razorpay Payment Id :</span>
                        <span>{paymentDetails.razorpay_payment_id}</span>
                      </div>
                      <div>
                        <span>Transection Id :</span>
                        <span>{paymentDetails.transectionId}</span>
                      </div>
                      <div>
                        <span>Payment By :</span>
                        <span
                          style={{
                            color: "#145f33",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            navigate(`/user/${paymentDetails.userDetails?._id}`)
                          }
                        >
                          {paymentDetails.userDetails?.name}
                        </span>
                      </div>
                      <div>
                        <span>Paid Amount :</span>
                        <span>{`Rs. ${
                          paymentDetails.otherData?.amount / 100
                        }/-`}</span>
                      </div>
                      <div>
                        <span>Payment Status :</span>
                        <span
                          className={`badge bg-${
                            paymentDetails.paymentStatus === "SUCCESS"
                              ? "success"
                              : "danger"
                          }`}
                        >
                          {paymentDetails?.paymentStatus}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default PaymentDetails;
