import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, ModalBody, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  changeSubscriptionStatus,
  getSubscriptionDetail,
} from "../../../../redux/slices/subscriptionSlice";

const SubscriptionDetails = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [editableData, setEditableData] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [profileImage, setProfileImage] = useState();
  const dispatch = useDispatch();
  const handleProfileImage = (e) => {
    setProfileImage(e.target.files[0]);
  };
  const { id } = useParams();
  const { subscriptionDetails, refetch } = useSelector(
    (state) => state.subscriptionSlice
  );

  const prevProps = useRef({ refetch }).current;

  useEffect(() => {
    if (prevProps.refetch != refetch) {
      setLoading(true);
      dispatch(getSubscriptionDetail(id, setLoading));
    }
    return () => {
      prevProps.refetch = refetch;
    };
  }, [refetch]);

  const handleChangeStatus = () => {
    setLoading(true);
    dispatch(changeSubscriptionStatus(id, setLoading, setModalShow));
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getSubscriptionDetail(id, setLoading));
  }, [id]);

  return (
    <>
      {loading ? (
        <div className="w-100 d-flex">
          <div class="spinner-border text-primary mx-auto" role="status"></div>
        </div>
      ) : (
        <div>
          <div className="page-header">
            <h3 className="page-title">Subscription Plan Details</h3>
          </div>
          <Row className="d-flex justify-content-center">
            <Col md={5} className="">
              <div className="card">
                <div className="card-body">
                  <Row>
                    <Col md={12} className="profile_info px-4">
                      <div>
                        <span>Status :</span>
                        <span className="d-flex align-items-center gap-3">
                          Active{" "}
                          <div
                            onClick={() => setModalShow(true)}
                            className="checkbox ms-auto w-100 me-2"
                          >
                            <input
                              type="checkbox"
                              id="cbx11"
                              checked={subscriptionDetails.active}
                              className="d-none toggle_switch"
                            />
                            <label for="cbx11" className="toggle mb-0 mt-1">
                              <span></span>
                            </label>
                          </div>
                        </span>
                      </div>
                      <div>
                        <span>Name :</span>
                        <span>{subscriptionDetails.name}</span>
                      </div>
                      <div>
                        <span>Price :</span>
                        <span>{subscriptionDetails.price}</span>
                      </div>
                      <div>
                        <span>Type :</span>
                        <span>{subscriptionDetails.type}</span>
                      </div>
                      <div>
                        <span>Members :</span>
                        <span>{subscriptionDetails.members}</span>
                      </div>
                      {subscriptionDetails.qrGeneration && (
                        <div>
                          <span>Qr Generation :</span>
                          <span>{subscriptionDetails.qrGeneration}</span>
                        </div>
                      )}
                      {subscriptionDetails.unlimitedCall && (
                        <div>
                          <span>Unlimited Call :</span>
                          <span>
                            {subscriptionDetails.unlimitedCall ? "YES" : "NO"}
                          </span>
                        </div>
                      )}
                      <div>
                        <span>Two Way Audio/Video Call :</span>
                        <span>
                          {subscriptionDetails.twoWayAudioVideoCall
                            ? "YES"
                            : "NO"}
                        </span>
                      </div>
                      <div>
                        <span>QR Scan Alert :</span>
                        <span>
                          {subscriptionDetails.qrScanAlert ? "YES" : "NO"}
                        </span>
                      </div>
                      <div>
                        <span>Visitor History :</span>
                        <span>
                          {subscriptionDetails.visitorHistory ? "YES" : "NO"}
                        </span>
                      </div>
                      <div>
                        <span>Visitor Photo Capture :</span>
                        <span>
                          {subscriptionDetails.visitorPhotoCapture
                            ? "YES"
                            : "NO"}
                        </span>
                      </div>
                      <div>
                        <span>Smart Month MIS-Report :</span>
                        <span>
                          {subscriptionDetails.smartMonthMisReport
                            ? "YES"
                            : "NO"}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <Modal
            show={modalShow}
            size="sx"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <ModalBody>
              <h4>Sure want to change status!</h4>
            </ModalBody>
            <Modal.Footer>
              <button
                type="button"
                className="btn_secondary"
                onClick={() => setModalShow(false)}
              >
                Not now
              </button>
              <button
                type="button"
                className="btn_primary"
                onClick={handleChangeStatus}
              >
                Yes!
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
};

export default SubscriptionDetails;
