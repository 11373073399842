import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, ModalBody, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  changeVoucherStatus,
  getVoucherDetail,
} from "../../../../redux/slices/voucherSlice";

const VoucherDetails = () => {
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const dispatch = useDispatch();

  const { id } = useParams();
  const { voucherDetails, refetch } = useSelector(
    (state) => state.voucherSlice
  );

  const prevProps = useRef({ refetch }).current;

  useEffect(() => {
    if (prevProps.refetch != refetch) {
      setLoading(true);
      dispatch(getVoucherDetail(id, setLoading));
    }
    return () => {
      prevProps.refetch = refetch;
    };
  }, [dispatch, id, refetch, prevProps]);

  const handleChangeStatus = () => {
    setLoading(true);
    dispatch(changeVoucherStatus(id, setLoading, setModalShow));
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getVoucherDetail(id, setLoading));
  }, [dispatch, id]);

  return (
    <>
      {loading ? (
        <div className="w-100 d-flex">
          <div class="spinner-border text-primary mx-auto" role="status"></div>
        </div>
      ) : (
        <div>
          <div className="page-header">
            <h3 className="page-title">Voucher Details</h3>
          </div>
          <Row className="d-flex justify-content-center">
            <Col md={5} className="">
              <div className="card">
                <div className="card-body">
                  <Row>
                    <Col md={12} className="profile_info px-4">
                      <div>
                        <span>Status :</span>
                        <span className="d-flex align-items-center gap-3">
                          Active{" "}
                          <div
                            onClick={() => setModalShow(true)}
                            className="checkbox ms-auto w-100 me-2"
                          >
                            <input
                              type="checkbox"
                              id="cbx11"
                              checked={voucherDetails.active}
                              className="d-none toggle_switch"
                            />
                            <label for="cbx11" className="toggle mb-0 mt-1">
                              <span></span>
                            </label>
                          </div>
                        </span>
                      </div>
                      <div>
                        <span>Voucher Code :</span>
                        <span>{voucherDetails.voucherCode}</span>
                      </div>
                      <div>
                        <span>Discount :</span>
                        <span>{voucherDetails.discount}</span>
                      </div>
                      <div>
                        <span>Expiry Date :</span>
                        <span>
                          {new Date(voucherDetails.expiryDate)
                            .toLocaleDateString("en-US", {
                              month: "2-digit",
                              day: "2-digit",
                              year: "numeric",
                            })
                            .replace(/\//g, "-")}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <Modal
            show={modalShow}
            size="sx"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <ModalBody>
              <h4>Sure want to change status!</h4>
            </ModalBody>
            <Modal.Footer>
              <button
                type="button"
                className="btn_secondary"
                onClick={() => setModalShow(false)}
              >
                Not now
              </button>
              <button
                type="button"
                className="btn_primary"
                onClick={handleChangeStatus}
              >
                Yes!
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
};

export default VoucherDetails;
