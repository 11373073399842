import React, { useEffect, useRef, useState } from "react";
import { FormGroup, Modal, ModalBody } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  createSubscription,
  getSubscriptionDetail,
  updateSubscription,
} from "../../../redux/slices/subscriptionSlice";

const AddSubscriptionModal = ({
  forUpdate,
  setForUpdate,
  id,
  setId,
  modalShow,
  setModalShow,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    type: "home", // default value
    price: null,
    members: 1,
    qrGeneration: 1,
    unlimitedCall: false,
    twoWayAudioVideoCall: false,
    qrScanAlert: false,
    visitorHistory: false,
    visitorPhotoCapture: false,
    smartMonthMisReport: false,
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const { subscriptionDetails } = useSelector(
    (state) => state.subscriptionSlice
  );
  const dispatch = useDispatch();

  const fieldLabels = {
    name: "Subscription Plan Name",
    price: "Price",
  };

  useEffect(() => {
    if (forUpdate && id) {
      dispatch(getSubscriptionDetail(id, setLoading));
    }
  }, [forUpdate, id, dispatch]);

  useEffect(() => {
    if (forUpdate && subscriptionDetails) {
      setFormData({
        name: subscriptionDetails.name,
        type: subscriptionDetails.type,
        price: subscriptionDetails.price,
        members: subscriptionDetails.members,
        qrGeneration: subscriptionDetails.qrGeneration,
        unlimitedCall: subscriptionDetails.unlimitedCall,
        twoWayAudioVideoCall: subscriptionDetails.twoWayAudioVideoCall,
        qrScanAlert: subscriptionDetails.qrScanAlert,
        visitorHistory: subscriptionDetails.visitorHistory,
        visitorPhotoCapture: subscriptionDetails.visitorPhotoCapture,
        smartMonthMisReport: subscriptionDetails.smartMonthMisReport,
      });
    }
  }, [subscriptionDetails, forUpdate]);

  const handleSubmitForm = async () => {
    if (!forUpdate) {
      const newErrors = {};

      // Validate only name and price fields
      if (!formData.name) {
        newErrors.name = `${fieldLabels.name} is required`;
      }
      if (!formData.price) {
        newErrors.price = `${fieldLabels.price} is required`;
      }

      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
        // Submit the form data
        dispatch(
          createSubscription(formData, setLoading, setModalShow, setFormData)
        );
      }
    } else {
      const newErrors = {};

      // Validate only name and price fields
      if (!formData.name) {
        newErrors.name = `${fieldLabels.name} is required`;
      }
      if (!formData.price) {
        newErrors.price = `${fieldLabels.price} is required`;
      }

      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
        // Submit the form data
        dispatch(
          updateSubscription(
            id,
            formData,
            setLoading,
            setModalShow,
            setFormData,
            setForUpdate,
            setId
          )
        );
      }
    }
  };

  const CheckboxField = ({ label, checked, onChange }) => {
    return (
      <FormGroup
        className="mb-3"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <label style={{ marginRight: "auto" }}>{label}</label>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input type="checkbox" checked={checked} onChange={onChange} />
          <span style={{ marginLeft: "8px" }}>{checked ? "Yes" : "No"}</span>
        </div>
      </FormGroup>
    );
  };

  return (
    <Modal
      show={modalShow}
      size="sx"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalBody>
        <h5 className="mb-3">Add New Subscription Plan</h5>

        <FormGroup className="mb-3">
          <label>Subscription Plan Name</label>
          <input
            type="text"
            placeholder="Enter Plan Name"
            value={formData.name}
            className="form-control"
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
        </FormGroup>
        {errors.name && <div className="text-danger">{errors.name}</div>}

        <FormGroup className="mb-3">
          <label>Subscription Type</label>
          <select
            className="form-control"
            value={formData.type}
            onChange={(e) => setFormData({ ...formData, type: e.target.value })}
          >
            <option value="home">Home</option>
            <option value="hotel">Hotel</option>
            <option value="car">Car</option>
          </select>
        </FormGroup>

        <FormGroup className="mb-3">
          <label>Price</label>
          <input
            type="number"
            placeholder="Enter Price"
            value={formData.price}
            className="form-control"
            onChange={(e) =>
              setFormData({ ...formData, price: e.target.value })
            }
          />
        </FormGroup>
        {errors.price && <div className="text-danger">{errors.price}</div>}

        <FormGroup className="mb-3">
          <label>Members</label>
          <input
            type="number"
            placeholder="Enter Number of Members"
            value={formData.members}
            className="form-control"
            onChange={(e) =>
              setFormData({ ...formData, members: e.target.value })
            }
          />
        </FormGroup>

        <FormGroup className="mb-3">
          <label>QR Generation</label>
          <input
            type="number"
            placeholder="Enter Number of QR-Generation"
            value={formData.qrGeneration}
            className="form-control"
            onChange={(e) =>
              setFormData({ ...formData, qrGeneration: e.target.value })
            }
          />
        </FormGroup>

        <CheckboxField
          label="Unlimited Call"
          checked={formData.unlimitedCall}
          onChange={() =>
            setFormData({ ...formData, unlimitedCall: !formData.unlimitedCall })
          }
        />
        <CheckboxField
          label="Two-way Audio/Video Calling"
          checked={formData.twoWayAudioVideoCall}
          onChange={() =>
            setFormData({
              ...formData,
              twoWayAudioVideoCall: !formData.twoWayAudioVideoCall,
            })
          }
        />
        <CheckboxField
          label="QR Scan Alert"
          checked={formData.qrScanAlert}
          onChange={() =>
            setFormData({
              ...formData,
              qrScanAlert: !formData.qrScanAlert,
            })
          }
        />
        <CheckboxField
          label="Visitor History"
          checked={formData.visitorHistory}
          onChange={() =>
            setFormData({
              ...formData,
              visitorHistory: !formData.visitorHistory,
            })
          }
        />
        <CheckboxField
          label="Visitor Photo Capture"
          checked={formData.visitorPhotoCapture}
          onChange={() =>
            setFormData({
              ...formData,
              visitorPhotoCapture: !formData.visitorPhotoCapture,
            })
          }
        />
        <CheckboxField
          label="Smart Month Mis-Report"
          checked={formData.smartMonthMisReport}
          onChange={() =>
            setFormData({
              ...formData,
              smartMonthMisReport: !formData.smartMonthMisReport,
            })
          }
        />
      </ModalBody>
      <Modal.Footer>
        <button
          type="button"
          className="btn_secondary"
          onClick={() => {
            setModalShow(false);
            setForUpdate(false);
            setId(false);
            setErrors({});
            setFormData({
              name: "",
              type: "home", // default value
              price: null,
              members: 1,
              qrGeneration: 1,
              unlimitedCall: false,
              twoWayAudioVideoCall: false,
              qrScanAlert: false,
              visitorHistory: false,
              visitorPhotoCapture: false,
              smartMonthMisReport: false,
            });
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn_primary"
          onClick={handleSubmitForm}
        >
          {forUpdate ? "Update" : "Add"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddSubscriptionModal;
