import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    subscriptions: [],
    subscriptionDetails: {},
    refetch: false,
  },
  reducers: {
    getAllSubscriptionSuccess: (state, action) => {
      state.subscriptions = action.payload;
    },
    getSubscriptionDetailSuccess: (state, action) => {
      state.subscriptionDetails = action.payload;
    },
    refetch: (state, action) => {
      state.refetch = action.payload;
    },
  },
});

export const {
  getAllSubscriptionSuccess,
  getSubscriptionDetailSuccess,
  refetch,
} = subscriptionSlice.actions;

export const getAllSubscriptions = (setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/subscription/admin/list/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    dispatch(getAllSubscriptionSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    if (error.response.data.status == (401 || 403)) {
      Navigate("/login");
    }
    setIsLoading(false);
  }
};

export const getSubscriptionDetail = (id, setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/subscription/admin/details/${id}/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    dispatch(getSubscriptionDetailSuccess(data.data));
    setLoading(false);
  } catch (error) {
    console.log(error);
    if (error.response.data.status == (401 || 403)) {
      Navigate("/login");
    }
    setLoading(false);
  }
};

export const createSubscription =
  (formData, setLoading, setModalShow, setFormData) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/subscription/create/v1`;
      const { data } = await axios.post(url, formData, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      setModalShow(false);
      setFormData({
        name: "",
        type: "home", // default value
        price: null,
        members: 1,
        qrGeneration: 1,
        unlimitedCall: false,
        twoWayAudioVideoCall: false,
        qrScanAlert: false,
        visitorHistory: false,
        visitorPhotoCapture: false,
        smartMonthMisReport: false,
      });
      dispatch(refetch(true));
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error.response.data.status == (401 || 403)) {
        Navigate("/login");
      }
      setLoading(false);
      setFormData({
        name: "",
        type: "home", // default value
        price: null,
        members: 1,
        qrGeneration: 1,
        unlimitedCall: false,
        twoWayAudioVideoCall: false,
        qrScanAlert: false,
        visitorHistory: false,
        visitorPhotoCapture: false,
        smartMonthMisReport: false,
      });
    }
  };

export const updateSubscription =
  (id, formData, setLoading, setModalShow, setFormData, setForUpdate, setId) =>
  async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/subscription/update/${id}/v1`;
      const { data } = await axios.put(url, formData, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      setModalShow(false);
      setForUpdate(false);
      setId(false);
      setFormData({
        name: "",
        type: "home", // default value
        price: null,
        members: 1,
        qrGeneration: 1,
        unlimitedCall: false,
        twoWayAudioVideoCall: false,
        qrScanAlert: false,
        visitorHistory: false,
        visitorPhotoCapture: false,
        smartMonthMisReport: false,
      });
      dispatch(refetch(true));
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error.response.data.status == (401 || 403)) {
        Navigate("/login");
      }
      setLoading(false);
      setFormData({
        name: "",
        type: "home", // default value
        price: null,
        members: 1,
        qrGeneration: 1,
        unlimitedCall: false,
        twoWayAudioVideoCall: false,
        qrScanAlert: false,
        visitorHistory: false,
        visitorPhotoCapture: false,
        smartMonthMisReport: false,
      });
    }
  };

export const deleteSubscription =
  (id, setLoading, setDeleteModal) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/subscription/delete/${id}/v1`;
      const { data } = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch(refetch(true));
      setDeleteModal(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error.response.data.status == (401 || 403)) {
        Navigate("/login");
      }
      setLoading(false);
    }
  };

export const changeSubscriptionStatus =
  (id, setLoading, setModalShow) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/subscription/change/status/${id}/v1`;
      const { data } = await axios.put(
        url,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch(refetch(true));
      setModalShow(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error.response.data.status == (401 || 403)) {
        Navigate("/login");
      }
      setLoading(false);
    }
  };

export default subscriptionSlice.reducer;
