import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

const productOrderSlice = createSlice({
  name: "productOrder",
  initialState: {
    productOrders: [],
    productOrder: [],
    refetch: false,
  },
  reducers: {
    getAllProductOrdersSuccess: (state, action) => {
      state.productOrders = action.payload;
    },
    getProductOrderDetailSuccess: (state, action) => {
      state.productOrder = action.payload;
    },
    refetch: (state, action) => {
      state.refetch = action.payload;
    },
  },
});

export const {
  getAllProductOrdersSuccess,
  getProductOrderDetailSuccess,
  refetch,
} = productOrderSlice.actions;

export const getAllProductOrders = (setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/product/order/admin/get/list/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    dispatch(getAllProductOrdersSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    if (error.response.data.status == (401 || 403)) {
      Navigate("/login");
    }
    setIsLoading(false);
  }
};

export const getProductOrderDetail = (id, setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/product/order/admin/get/details/${id}/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    dispatch(getProductOrderDetailSuccess(data.data));
    setLoading(false);
  } catch (error) {
    console.log(error);
    if (error.response.data.status == (401 || 403)) {
      Navigate("/login");
    }
    setLoading(false);
  }
};

export const acceptProductOrder =
  (id, deliveryDate, setModalShow, setDeliveryDate, setLoading) =>
  async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/product/order/accept/${id}/v1`;
      const { data } = await axios.put(
        url,
        { deliveryDate },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch(refetch(true));
      setModalShow(false);
      setDeliveryDate("");
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error.response.data.status == (401 || 403)) {
        Navigate("/login");
      }
      setLoading(false);
    }
  };

export const rejectProductOrder =
  (id, setModalShow, setLoading) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/product/order/reject/${id}/v1`;
      const { data } = await axios.put(
        url,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch(refetch(true));
      setModalShow(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error.response.data.status == (401 || 403)) {
        Navigate("/login");
      }
      setLoading(false);
    }
  };

export const updateProductDeliveryDate =
  (id, deliveryDate, setModalShow, setDeliveryDate, setLoading) =>
  async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/product/order/change/delivery/date/${id}/v1`;
      const { data } = await axios.put(
        url,
        { deliveryDate },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch(refetch(true));
      setModalShow(false);
      setDeliveryDate("");
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error.response.data.status == (401 || 403)) {
        Navigate("/login");
      }
      setLoading(false);
    }
  };

export const updateProductDeliveryStatus =
  (id, deliveryStatus, setModalShow, setDeliveryStatus, setLoading) =>
  async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/product/order/change/delivery/status/${id}/v1`;
      const { data } = await axios.put(
        url,
        { deliveryStatus },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch(refetch(true));
      setModalShow(false);
      setDeliveryStatus("");
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error.response.data.status == (401 || 403)) {
        Navigate("/login");
      }
      setLoading(false);
    }
  };

export default productOrderSlice.reducer;
