import React, { useEffect, useRef, useState } from "react";
import { FormGroup, Modal, ModalBody } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { UploadSimple } from "@phosphor-icons/react";
import { toast } from "react-toastify";
import { uploadImage } from "../../../helper";
import {
  createProduct,
  getProductDetail,
  updateProduct,
} from "../../../redux/slices/productSlice";

const IMG_URL = process.env.REACT_APP_AWS_IMAGE_URL;

const AddProductModal = ({
  forUpdate,
  setForUpdate,
  id,
  setId,
  modalShow,
  setModalShow,
}) => {
  const [formData, setFormData] = useState({
    image: null,
    productName: "",
    description: "",
    price: null,
  });
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const { product } = useSelector((state) => state.productSlice);

  useEffect(() => {
    if (forUpdate && id) {
      dispatch(getProductDetail(id, setLoading));
    }
  }, [forUpdate, id, dispatch]);

  useEffect(() => {
    if (forUpdate && product) {
      setFormData({
        image: product.image,
        productName: product.productName,
        description: product.description,
        price: product.price,
      });
    }
  }, [product, forUpdate]);

  const handleSelectImage = (e) => {
    const chosenFile = e.target.files[0];
    if (chosenFile) {
      setSelectedImage(chosenFile);
      fileInputRef.current.value = "";
    } else {
      toast.info("Please select an image file", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 7000,
      });
    }
  };

  const fieldLabels = {
    productName: "Product Name",
    description: "Product Description",
    price: "Product Price",
    image: "Product Image",
    // Add other fields as needed
  };

  const handleSubmitProductForm = async () => {
    if (forUpdate) {
      const newErrors = {};
      if (selectedImage) {
        formData.image = selectedImage;
      }

      Object.keys(formData).forEach((key) => {
        if (!formData[key]) {
          newErrors[key] = `*${fieldLabels[key] || key} is required`;
        }
      });

      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        if (selectedImage) {
          formData.image = await uploadImage(
            "/product/upload/image/v1",
            selectedImage,
            setSelectedImage
          );
        }
        dispatch(
          updateProduct(
            id,
            formData,
            setLoading,
            setModalShow,
            setFormData,
            setForUpdate,
            setId
          )
        );
      }
    } else {
      const newErrors = {};

      formData.image = selectedImage;
      Object.keys(formData).forEach((key) => {
        if (!formData[key]) {
          newErrors[key] = `${fieldLabels[key] || key} is required`;
        }
      });

      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        formData.image = await uploadImage(
          "/product/upload/image/v1",
          selectedImage,
          setSelectedImage
        );
        dispatch(
          createProduct(formData, setLoading, setModalShow, setFormData)
        );
      }
    }
  };

  const renderImagePreview = () => {
    if (formData.image && !selectedImage) {
      // If the image is a URL, display it directly
      return (
        <img
          className="w-100"
          style={{ aspectRatio: "4/3" }}
          src={`${IMG_URL}${formData.image}`}
          alt="Selected Banner"
        />
      );
    } else if (selectedImage) {
      // If the image is a File object, create an object URL
      return (
        <img
          className="w-100"
          style={{ aspectRatio: "4/3" }}
          src={URL.createObjectURL(selectedImage)}
          alt="Selected Banner"
        />
      );
    }
    return null;
  };

  return (
    <Modal
      show={modalShow}
      size="sx"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalBody>
        <h5 className="mb-3">Add New Product</h5>
        <FormGroup>
          <label htmlFor="product" className="upload_wrapper">
            <input
              required={true}
              className="form-control"
              ref={fileInputRef}
              type="file"
              id="product"
              onChange={handleSelectImage}
            />
            <UploadSimple size={32} />
            <div className="content">Upload Product Image</div>
            <div className="upload_btn">Upload</div>
          </label>
          {(selectedImage || formData.image) && (
            <div className="image_view">
              <button
                className="close"
                onClick={() => {
                  setSelectedImage(null);
                  setFormData({ ...formData, image: null });
                }}
              >
                &times;
              </button>
              {renderImagePreview()}
            </div>
          )}
          <p className="file_upload_instructions">
            Upload (.png) file (128px * 128px)
          </p>
          {errors.image && <div className="text-danger">{errors.image}</div>}
        </FormGroup>
        <FormGroup className="mb-3">
          <label>Product Name</label>
          <input
            type="text"
            placeholder="Enter Product Name"
            value={formData.productName}
            className="form-control"
            onChange={(e) =>
              setFormData({ ...formData, productName: e.target.value })
            }
            required={true}
          />
        </FormGroup>
        {errors.productName && (
          <div className="text-danger">{errors.productName}</div>
        )}
        <FormGroup className="mb-3">
          <label>Product Description</label>
          <textarea
            required
            className="form-control"
            placeholder="Enter Product Description"
            value={formData.description}
            onChange={(e) =>
              setFormData({
                ...formData,
                description: e.target.value,
              })
            }
          />
        </FormGroup>
        {errors.description && (
          <div className="text-danger">{errors.description}</div>
        )}
        <FormGroup className="mb-3">
          <label>Price (₹)</label>
          <input
            type="number"
            placeholder="Enter Product Price"
            value={formData.price}
            className="form-control"
            onChange={(e) =>
              setFormData({ ...formData, price: e.target.value })
            }
            required={true}
          />
        </FormGroup>
        {errors.price && <div className="text-danger">{errors.price}</div>}
      </ModalBody>
      <Modal.Footer>
        <button
          type="button"
          className="btn_secondary"
          onClick={() => {
            setForUpdate(false);
            setModalShow(false);
            setErrors({});
            setId("");
            setSelectedImage(null);
            setFormData({
              image: null,
              productName: "",
              description: "",
              price: null,
            });
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn_primary"
          onClick={handleSubmitProductForm}
        >
          {forUpdate ? "Update" : "Add"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddProductModal;
