import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    payments: [],
    paymentDetails: {},
    refetch: false,
  },
  reducers: {
    getAllPaymentsSuccess: (state, action) => {
      state.payments = action.payload;
    },
    getPaymentDetailSuccess: (state, action) => {
      state.paymentDetails = action.payload;
    },
    refetch: (state, action) => {
      state.refetch = action.payload;
    },
  },
});

export const { getAllPaymentsSuccess, getPaymentDetailSuccess, refetch } =
  paymentSlice.actions;

export const getAllPayments = (setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/payment/list/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    dispatch(getAllPaymentsSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    if(error.response.data.status==(401 || 403 )){
      Navigate("/login")
    }
    setIsLoading(false);
  }
};

export const getPaymentDetails = (id, setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/payment/details/${id}/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    dispatch(getPaymentDetailSuccess(data.data));
    setLoading(false);
  } catch (error) {
    console.log(error);
    if(error.response.data.status==(401 || 403 )){
      Navigate("/login")
    }
    setLoading(false);
  }
};

export const deletePayment =
  (id, setLoading, setDeleteModal) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/payment/delete/${id}/v1`;
      const { data } = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch(refetch(true));
      setDeleteModal(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if(error.response.data.status==(401 || 403 )){
        Navigate("/login")
      }
      setLoading(false);
    }
  };

export default paymentSlice.reducer;
