import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Row, Col, Modal, Form } from "react-bootstrap";
import { FiEdit } from "react-icons/fi";
import { Trash } from "@phosphor-icons/react";
import { useDispatch, useSelector } from "react-redux";
import {
  createHelpAndFaq,
  getAllFaqLists,
  getFaqDetails,
  updateHelpAndFaq,
} from "../../../../redux/slices/faqSlics";
import DeleteModal from "../../components/DeleteModal";

const FAQ = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    question: "",
    answer: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [forUpdate, setForUpdate] = useState(false);
  const [error, setError] = useState("");
  const { faqs, refetch, faq } = useSelector((state) => state.faqSlice);
  const [deleteModal, setDeleteModal] = useState(false);
  const [id, setId] = useState("");
  const handleClose = () => {
    setShow(false);
    setFormData({
      question: "",
      answer: "",
    });
    setId("");
    setForUpdate(false);
  };
  const handleShow = () => setShow(true);
  const prevProps = useRef({ refetch }).current;

  useEffect(() => {
    dispatch(getAllFaqLists(setIsLoading));
  }, [dispatch]);

  useEffect(() => {
    if (prevProps.refetch !== refetch) {
      dispatch(getAllFaqLists(setIsLoading));
    }
    return () => {
      prevProps.refetch = refetch;
    };
  }, [dispatch, prevProps, refetch]);

  useEffect(() => {
    if (id && forUpdate) {
      dispatch(getFaqDetails(id, setIsLoading));
    }
  }, [dispatch, id, forUpdate]);

  useEffect(() => {
    if (faq) {
      setFormData({
        question: faq.question,
        answer: faq.answer,
      });
    }
  }, [faq]);

  const addFaq = () => {
    if (forUpdate) {
      if (!formData.question.trim() || !formData.answer.trim()) {
        setError("Both question and answer are required.");
        return;
      }
      setIsLoading(true);
      dispatch(updateHelpAndFaq(id, formData, setIsLoading, setFormData));

      setError("");
      setForUpdate(false);
      setId("");
      handleClose();
    } else {
      if (!formData.question.trim() || !formData.answer.trim()) {
        setError("Both question and answer are required.");
        return;
      }
      setIsLoading(true);
      dispatch(createHelpAndFaq(formData, setIsLoading, setFormData));
      setId("");
      setError("");
      handleClose();
    }
  };

  const deleteFaq = async (id) => {
    setId(id);
    setDeleteModal(true);
  };

  const editFaq = (id) => {
    setId(id);
    setShow(true);
    setForUpdate(true);
  };

  return (
    <div className="container mt-5">
      <Row className="mb-3">
        <Col>
          <h4>FAQs</h4>
        </Col>
        <Col md={4} className="d-flex align-items-end">
          <button
            className="btn_primary_outline_sm ms-auto"
            onClick={handleShow}
          >
            Create More FAQs
          </button>
        </Col>
      </Row>
      {faqs.map((faq) => (
        <Card className="mb-3" key={faq._id}>
          <Card.Body>
            <Row>
              <Col md={10}>
                <Card.Title>{faq.question}</Card.Title>
                <Card.Text>{faq.answer}</Card.Text>
              </Col>
              <Col md={2} className="text-right mt-2">
                <FiEdit
                  style={{ cursor: "pointer" }}
                  size={20}
                  onClick={() => editFaq(faq._id)}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Trash
                  style={{ cursor: "pointer" }}
                  size={22}
                  onClick={() => deleteFaq(faq._id)}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ))}

      {/* Modal for Adding New FAQ */}
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{`${forUpdate ? "Update" : "Add New"} FAQ`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Question</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Add your question here"
                value={formData.question}
                onChange={(e) =>
                  setFormData({ ...formData, question: e.target.value })
                }
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Answer</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Add your answer here"
                value={formData.answer}
                onChange={(e) =>
                  setFormData({ ...formData, answer: e.target.value })
                }
                required
              />
            </Form.Group>

            {error && <p className="text-danger">{error}</p>}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="success" onClick={addFaq}>
            {isLoading ? "Submitting" : "Submit"}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for delete FAQ*/}

      <DeleteModal
        forFAQ
        id={id}
        setId={setId}
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
      />
    </div>
  );
};

export default FAQ;
