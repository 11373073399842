import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { TbBrandAndroid } from "react-icons/tb";
import { RiAppleLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import {
  getSetting,
  updateSetting,
} from "../../../redux/slices/appsettingsSlice";

const AppSetting = () => {
  const dispatch = useDispatch();
  const { setting } = useSelector((state) => state.appsettingsSlice);

  const [androidLoading, setAndroidLoading] = useState(false);
  const [iosLoading, setIosLoading] = useState(false);
  const [loading, setLoading] = useState("");
  const [formData, setFormData] = useState({
    android: {
      forcefullyUpdate: null,
      message: "",
      title: "",
      version: "",
    },
    ios: {
      forcefullyUpdate: null,
      message: "",
      title: "",
      version: "",
    },
  });

  useEffect(() => {
    setLoading(true);
    dispatch(getSetting(setLoading));
  }, [dispatch]);

  useEffect(() => {
    if (setting) {
      setFormData({ ...setting });
    }
  }, [setting]);

  const handleInputChange = (field, value, platform) => {
    if (field === "version") {
      const numericRegex = /^[0-9.]*$/;
      if (numericRegex.test(value) || value === "") {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [platform]: {
            ...prevFormData[platform],
            [field]: value,
          },
        }));
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [platform]: {
          ...prevFormData[platform],
          [field]: value,
        },
      }));
    }
  };

  const handleSubmit = (e, platform) => {
    e.preventDefault();
    platform === "android" ? setAndroidLoading(true) : setIosLoading(true);

    const updatedSetting = {
      android: formData?.android,
      ios: formData?.ios,
      key: formData?.key,
    };

    dispatch(
      updateSetting(updatedSetting, () => {
        platform === "android"
          ? setAndroidLoading(false)
          : setIosLoading(false);
      })
    );
  };

  return (
    <>
      {loading ? (
        <div className="w-100 d-flex">
          <div
            className="spinner-border text-primary mx-auto"
            role="status"
          ></div>
        </div>
      ) : (
        <div>
          <div className="page-header">
            <h3 className="page-title">App Setting</h3>
          </div>
          <Row className="d-flex justify-content-center">
            <Col md={4}>
              <div className="card">
                <div className="card-body">
                  <h6 className="card-title d-flex align-items-center">
                    <TbBrandAndroid />
                    &nbsp;<b>Android Version</b>
                  </h6>
                  <Row>
                    <Col md={12} className="profile_info">
                      <div className="mb-3 input_dark flex-column align-items-start">
                        <label htmlFor="androidVersion" className="form-label">
                          Version
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="androidVersion"
                          placeholder="0.1.0"
                          value={formData?.android?.version}
                          onChange={(e) =>
                            handleInputChange(
                              "version",
                              e.target.value,
                              "android"
                            )
                          }
                        />
                      </div>
                      <div className="mb-3 input_dark flex-column align-items-start">
                        <label htmlFor="androidTitle" className="form-label">
                          Title
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="androidTitle"
                          placeholder="Problem has been Solved"
                          value={formData?.android?.title}
                          onChange={(e) =>
                            handleInputChange(
                              "title",
                              e.target.value,
                              "android"
                            )
                          }
                        />
                      </div>
                      <div className="mb-3 input_dark flex-column align-items-start">
                        <label htmlFor="androidMessage" className="form-label">
                          Message
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="androidMessage"
                          placeholder="Kindly Update the app for better experience"
                          value={formData?.android?.message}
                          onChange={(e) =>
                            handleInputChange(
                              "message",
                              e.target.value,
                              "android"
                            )
                          }
                        />
                      </div>
                      <div>
                        <span>Force Update :</span>
                        <span className="d-flex align-items-center gap-3">
                          <div className="checkbox ms-auto w-100 me-2">
                            <input
                              type="checkbox"
                              id="androidForceUpdate"
                              className="d-none toggle_switch"
                              checked={formData?.android?.forcefullyUpdate}
                              onChange={(e) =>
                                handleInputChange(
                                  "forcefullyUpdate",
                                  e.target.checked,
                                  "android"
                                )
                              }
                            />
                            <label
                              htmlFor="androidForceUpdate"
                              className="toggle mb-0 mt-1"
                            >
                              <span></span>
                            </label>
                          </div>
                        </span>
                      </div>
                    </Col>
                    <Col md={6}>
                      <button
                        disabled={androidLoading}
                        className="btn_primary_outline_sm py-2 mt-4 w-100"
                        onClick={(e) => handleSubmit(e, "android")}
                      >
                        {androidLoading ? <Spinner /> : "Update"}
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="card">
                <div className="card-body">
                  <h6 className="card-title d-flex align-items-center">
                    <RiAppleLine strokeWidth={0.5} size={16} />
                    &nbsp;<b> iOS Version</b>
                  </h6>
                  <Row>
                    <Col md={12} className="profile_info">
                      <div className="mb-3 input_dark flex-column align-items-start">
                        <label htmlFor="iosVersion" className="form-label">
                          Version
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="iosVersion"
                          placeholder="0.1.0"
                          value={formData?.ios?.version}
                          onChange={(e) =>
                            handleInputChange("version", e.target.value, "ios")
                          }
                        />
                      </div>
                      <div className="mb-3 input_dark flex-column align-items-start">
                        <label htmlFor="iosTitle" className="form-label">
                          Title
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="iosTitle"
                          placeholder="Problem has been Solved"
                          value={formData?.ios?.title}
                          onChange={(e) =>
                            handleInputChange("title", e.target.value, "ios")
                          }
                        />
                      </div>
                      <div className="mb-3 input_dark flex-column align-items-start">
                        <label htmlFor="iosMessage" className="form-label">
                          Message
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="iosMessage"
                          placeholder="Kindly Update the app for better experience"
                          value={formData?.ios?.message}
                          onChange={(e) =>
                            handleInputChange("message", e.target.value, "ios")
                          }
                        />
                      </div>
                      <div>
                        <span>Force Update :</span>
                        <span className="d-flex align-items-center gap-3">
                          <div className="checkbox ms-auto w-100 me-2">
                            <input
                              type="checkbox"
                              id="iosForceUpdate"
                              className="d-none toggle_switch"
                              checked={formData?.ios?.forcefullyUpdate}
                              onChange={(e) =>
                                handleInputChange(
                                  "forcefullyUpdate",
                                  e.target.checked,
                                  "ios"
                                )
                              }
                            />
                            <label
                              htmlFor="iosForceUpdate"
                              className="toggle mb-0 mt-1"
                            >
                              <span></span>
                            </label>
                          </div>
                        </span>
                      </div>
                    </Col>
                    <Col md={6}>
                      <button
                        disabled={iosLoading}
                        className="btn_primary_outline_sm py-2 mt-4 w-100"
                        onClick={(e) => handleSubmit(e, "ios")}
                      >
                        {iosLoading ? <Spinner /> : "Update"}
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default AppSetting;
