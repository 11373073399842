import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

const contactusSlice = createSlice({
  name: "contactus",
  initialState: {
    contactUsList: [],
    contactUsDetails: {},
    refetch: false,
  },
  reducers: {
    getAllContactUsListSuccess: (state, action) => {
      state.contactUsList = action.payload;
    },
    getContactUsDetailSuccess: (state, action) => {
      state.contactUsDetails = action.payload;
    },
    refetch: (state, action) => {
      state.refetch = action.payload;
    },
  },
});

export const {
  getAllContactUsListSuccess,
  getContactUsDetailSuccess,
  refetch,
} = contactusSlice.actions;

export const getAllContactUsList = (setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/contactus/list/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    dispatch(getAllContactUsListSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    if (error.response.data.status == (401 || 403)) {
      Navigate("/login");
    }
    setIsLoading(false);
  }
};

export const getContactUsDetail = (id, setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/contactus/details/${id}/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    dispatch(getContactUsDetailSuccess(data.data));
    setLoading(false);
  } catch (error) {
    console.log(error);
    if (error.response.data.status == (401 || 403)) {
      Navigate("/login");
    }
    setLoading(false);
  }
};

export const deleteContactUs =
  (id, setLoading, setDeleteModal) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/contactus/delete/${id}/v1`;
      const { data } = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch(refetch(true));
      setDeleteModal(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error.response.data.status == (401 || 403)) {
        Navigate("/login");
      }
      setLoading(false);
    }
  };
export default contactusSlice.reducer;
