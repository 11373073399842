import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import userSlice from "./slices/userSlice";
import productSlice from "./slices/productSlice";
import orderSlice from "./slices/orderSlice";
import contactusSlice from "./slices/contactusSlice";
import qrSlice from "./slices/qrSlice";
import paymentSlice from "./slices/paymentSlice";
import subscriptionSlice from "./slices/subscriptionSlice";
import appsettingsSlice from "./slices/appsettingsSlice";
import dashboardSlice from "./slices/dashboardSlice";
import webProductSlice from "./slices/webProductSlice";
import productOrderSlice from "./slices/productOrderSlice";
import voucherSlice from "./slices/voucherSlice";
import callSlice from "./slices/callSlice";
import faqSlice from "./slices/faqSlics";

export default configureStore({
  reducer: {
    authSlice,
    userSlice,
    productSlice,
    orderSlice,
    contactusSlice,
    qrSlice,
    paymentSlice,
    subscriptionSlice,
    appsettingsSlice,
    dashboardSlice,
    webProductSlice,
    productOrderSlice,
    voucherSlice,
    callSlice,
    faqSlice,
  },
});
