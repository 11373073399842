import React, { Suspense, lazy, useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import Spinner from "../app/views/shared/Spinner";
import { useDispatch } from "react-redux";
import Product from "./views/pages/product/Product";
import ProductDetails from "./views/pages/product/ProductDetails";
import Order from "./views/pages/order/Order";
import OrderDetails from "./views/pages/order/OrderDetails";
import ContactUs from "./views/pages/contactus/ContactUs";
import ContactUsDetails from "./views/pages/contactus/ContactUsDetails";
import Qr from "./views/pages/qr/Qr";
import QrDetails from "./views/pages/qr/QrDetails";
import Payments from "./views/pages/payments/Payments";
import PaymentDetails from "./views/pages/payments/PaymentDetails";
import Subscription from "./views/pages/subscription/Subscription";
import SubscriptionDetails from "./views/pages/subscription/SubscriptionDetails";
import AppSetting from "./views/pages/AppSetting";
import WebProducts from "./views/pages/webProducts/WebProducts";
import WebProductDetails from "./views/pages/webProducts/WebProductDetails";
import ProductOrder from "./views/pages/productOrder/ProductOrder";
import ProductOrderDetails from "./views/pages/productOrder/ProductOrderDetails";
import Voucher from "./views/pages/voucher/Voucher";
import VoucherDetails from "./views/pages/voucher/VoucherDetails";
import CallLogs from "./views/pages/callLogs/CallLogs";
import FAQ from "./views/pages/faq/FAQ";

const Dashboard = lazy(() => import("./views/pages/dashboard/Dashboard"));
const Profile = lazy(() => import("./views/pages/Profile"));
const Users = lazy(() => import("./views/pages/user/Users"));
const UserDetail = lazy(() => import("./views/pages/user/UserDetail"));
const Error404 = lazy(() => import("./views/pages/error/Error404"));
const Error500 = lazy(() => import("./views/pages/error/Error500"));
const Auth = lazy(() => import("./views/pages/auth/Auth"));

const SecureRoute = ({ redirectPath = "/login", children }) => {
  if (!localStorage.getItem("token")) {
    return <Navigate to={redirectPath} replace />;
  }
  return children;
};

const AppRoutes = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // const token = localStorage.getItem("token");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route path="/login" element={<Auth />} />{" "}
        <Route path="/forgot_password" element={<Auth />} />
        <Route path="/reset_password/:resetToken" element={<Auth />} />
        <Route
          path="/change_password"
          element={
            <SecureRoute>
              <Auth />
            </SecureRoute>
          }
        />
        <Route
          path="/"
          element={
            <SecureRoute>
              <Dashboard />
            </SecureRoute>
          }
        />
        <Route
          path="/users"
          element={
            <SecureRoute>
              <Users />
            </SecureRoute>
          }
        />
        <Route
          path="/user/:id"
          element={
            <SecureRoute>
              <UserDetail />
            </SecureRoute>
          }
        />
        <Route
          path="/products"
          element={
            <SecureRoute>
              <Product />
            </SecureRoute>
          }
        />
        <Route
          path="/product/:id"
          element={
            <SecureRoute>
              <ProductDetails />
            </SecureRoute>
          }
        />
        <Route
          path="/web-products"
          element={
            <SecureRoute>
              <WebProducts />
            </SecureRoute>
          }
        />
        <Route
          path="/web-product/:id"
          element={
            <SecureRoute>
              <WebProductDetails />
            </SecureRoute>
          }
        />
        <Route
          path="/orders"
          element={
            <SecureRoute>
              <Order />
            </SecureRoute>
          }
        />
        <Route
          path="/order/:id"
          element={
            <SecureRoute>
              <OrderDetails />
            </SecureRoute>
          }
        />
        <Route
          path="/product-orders"
          element={
            <SecureRoute>
              <ProductOrder />
            </SecureRoute>
          }
        />
        <Route
          path="/product-order/:id"
          element={
            <SecureRoute>
              <ProductOrderDetails />
            </SecureRoute>
          }
        />
        <Route
          path="/call-logs"
          element={
            <SecureRoute>
              <CallLogs />
            </SecureRoute>
          }
        />
        <Route
          path="/payments"
          element={
            <SecureRoute>
              <Payments />
            </SecureRoute>
          }
        />
        <Route
          path="/payment/:id"
          element={
            <SecureRoute>
              <PaymentDetails />
            </SecureRoute>
          }
        />
        <Route
          path="/vouchers"
          element={
            <SecureRoute>
              <Voucher />
            </SecureRoute>
          }
        />
        <Route
          path="/voucher/:id"
          element={
            <SecureRoute>
              <VoucherDetails />
            </SecureRoute>
          }
        />
        <Route
          path="/contacts-us"
          element={
            <SecureRoute>
              <ContactUs />
            </SecureRoute>
          }
        />
        <Route
          path="/contact-us/:id"
          element={
            <SecureRoute>
              <ContactUsDetails />
            </SecureRoute>
          }
        />
        <Route
          path="/qrs"
          element={
            <SecureRoute>
              <Qr />
            </SecureRoute>
          }
        />
        <Route
          path="/qr/:id"
          element={
            <SecureRoute>
              <QrDetails />
            </SecureRoute>
          }
        />
        <Route
          path="/subscriptions"
          element={
            <SecureRoute>
              <Subscription />
            </SecureRoute>
          }
        />
        <Route
          path="/subscription/:id"
          element={
            <SecureRoute>
              <SubscriptionDetails />
            </SecureRoute>
          }
        />
        <Route
          path="/faqs"
          element={
            <SecureRoute>
              <FAQ />
            </SecureRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <SecureRoute>
              <AppSetting />
            </SecureRoute>
          }
        />
        <Route
          exact
          path="/profile"
          element={
            <SecureRoute>
              <Profile />
            </SecureRoute>
          }
        />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
