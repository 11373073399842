import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, ModalBody, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  changeProductStatus,
  getProductDetail,
} from "../../../../redux/slices/webProductSlice";

const IMG_URL = process.env.REACT_APP_AWS_IMAGE_URL;

const WebProductDetails = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const { id } = useParams();
  const { product, refetch } = useSelector((state) => state.webProductSlice);
  const prevProps = useRef({ refetch }).current;

  useEffect(() => {
    if (prevProps.refetch !== refetch) {
      setLoading(true);
      dispatch(getProductDetail(id, setLoading));
    }
    return () => {
      prevProps.refetch = refetch;
    };
  }, [dispatch, prevProps, refetch, id]);

  useEffect(() => {
    setLoading(true);
    dispatch(getProductDetail(id, setLoading));
  }, [dispatch, id]);

  const handleChangeStatus = () => {
    setLoading(true);
    dispatch(changeProductStatus(id, setLoading, setModalShow));
  };

  return (
    <>
      {loading ? (
        <div className="w-100 d-flex">
          <div class="spinner-border text-primary mx-auto" role="status"></div>
        </div>
      ) : (
        <div>
          <div className="row">
            <div className="col-lg-5 mx-auto grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <Row>
                    <Col className="mx-auto" md={10}>
                      <Row>
                        <Col md={12}>
                          <div class="my-3 input_dark mx-auto">
                            <label
                              for="exampleInputEmail1"
                              class="form-label w-100 mb-0"
                            >
                              <span className="d-flex w-100 align-items-center gap-3">
                                Status :{" "}
                                <div
                                  onClick={() => setModalShow(true)}
                                  className="checkbox ms-auto me-2"
                                >
                                  <input
                                    type="checkbox"
                                    id="cbx11"
                                    checked={product.active}
                                    className="d-none toggle_switch"
                                  />
                                  <label
                                    for="cbx11"
                                    className="toggle mb-0 mt-1"
                                  >
                                    <span></span>
                                  </label>
                                </div>
                              </span>
                            </label>
                          </div>
                        </Col>
                        <Col md={12}>
                          <div class="mb-3 input_dark">
                            <label for="exampleInputEmail1" class="form-label">
                              Product Image
                            </label>
                            <div>
                              <img
                                className="w-100"
                                src={`${IMG_URL}${product.image}`}
                                alt="Productimage"
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md={12}>
                          <div class="mb-3 input_dark">
                            <label for="exampleInputEmail1" class="form-label">
                              Product Title
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="exampleInputEmail1"
                              value={product.productName}
                              placeholder="Product Title"
                              aria-describedby="emailHelp"
                              readOnly
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div class="mb-3 input_dark">
                            <label for="exampleInputEmail1" class="form-label">
                              Product Description
                            </label>
                            <textarea
                              type="text"
                              class="form-control"
                              id="exampleInputEmail1"
                              value={product.description}
                              placeholder="Product Title"
                              aria-describedby="emailHelp"
                              readOnly
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div class="mb-3 input_dark">
                            <label for="exampleInputEmail1" class="form-label">
                              Product Type
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="exampleInputEmail1"
                              value={
                                product.type == "home"
                                  ? "Home"
                                  : product.type == "hotel"
                                  ? "Hotel"
                                  : "Car"
                              }
                              placeholder="Product Type"
                              aria-describedby="emailHelp"
                              readOnly
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Modal
                    show={modalShow}
                    size="sx"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <ModalBody>
                      <h4>Sure want to change status!</h4>
                    </ModalBody>
                    <Modal.Footer>
                      <button
                        type="button"
                        className="btn_secondary"
                        onClick={() => setModalShow(false)}
                      >
                        Not now
                      </button>
                      <button
                        type="button"
                        className="btn_primary"
                        onClick={handleChangeStatus}
                      >
                        Yes!
                      </button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WebProductDetails;
