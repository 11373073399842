import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

const qrSlice = createSlice({
  name: "qr",
  initialState: {
    qrs: [],
    qrDetails: {},
    refetch: false,
  },
  reducers: {
    getAllQrsSuccess: (state, action) => {
      state.qrs = action.payload;
    },
    getQrDetailSuccess: (state, action) => {
      state.qrDetails = action.payload;
    },
    refetch: (state, action) => {
      state.refetch = action.payload;
    },
  },
});

export const { getAllQrsSuccess, getQrDetailSuccess, refetch } =
  qrSlice.actions;

export const getAllQrs = (setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/qr/admin/list/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    dispatch(getAllQrsSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    if (error.response.data.status == (401 || 403)) {
      Navigate("/login");
    }
    setIsLoading(false);
  }
};

export const getQrDetail = (id, setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/qr/admin/details/${id}/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    dispatch(getQrDetailSuccess(data.data));
    setLoading(false);
  } catch (error) {
    console.log(error);
    if (error.response.data.status == (401 || 403)) {
      Navigate("/login");
    }
    setLoading(false);
  }
};

export const deleteQr =
  (id, setLoading, setDeleteModal) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/qr/delete/${id}/v1`;
      const { data } = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch(refetch(true));
      setDeleteModal(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error.response.data.status == (401 || 403)) {
        Navigate("/login");
      }
      setLoading(false);
    }
  };

export const changeQrStatus =
  (id, setLoading, setModalShow) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/qr/admin/change/status/${id}/v1`;
      const { data } = await axios.put(
        url,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch(refetch(true));
      setModalShow(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error.response.data.status == (401 || 403)) {
        Navigate("/login");
      }
      setLoading(false);
    }
  };

export default qrSlice.reducer;
