import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

const appsettingsSlice = createSlice({
  name: "appsetting",
  initialState: {
    loading: false,
    setting: {},
  },
  reducers: {
    getSettingSuccess: (state, action) => {
      state.setting = action.payload;
    },
  },
});
const { getSettingSuccess } = appsettingsSlice.actions;

export const getSetting = (setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/setting/get/app/version/v1`;
    const res = await axios.get(url);
    dispatch(getSettingSuccess(res.data.data));
    setLoading(false);
  } catch (error) {
    console.log(error);
    if (error.response.data.status == (401 || 403)) {
      Navigate("/login");
    }
    toast.error(error.response.data.message, {
      position: "top-center",
      autoClose: 5000,
    });
    setLoading(false);
  }
};

export const updateSetting = (payload, setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/setting/admin/update/app/version/v1`;
    const res = await axios.put(url, payload, {
      headers: { Authorization: localStorage.getItem("token") },
    });
    setLoading(false);
  } catch (error) {
    console.log(error);
    if (error.response.data.status == (401 || 403)) {
      Navigate("/login");
    }
    toast.error(error.response.data.message, {
      position: "top-center",
      autoClose: 5000,
    });
    setLoading(false);
  }
};

export default appsettingsSlice.reducer;
