import React, { useEffect, useMemo, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { arrowRight } from "../../../../assets/svg/Svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getContactUsDetail } from "../../../../redux/slices/contactusSlice";

const ContactUsDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  const { contactUsDetails, refetch } = useSelector(
    (state) => state.contactusSlice
  );

  useEffect(() => {
    setIsLoading(true);
    dispatch(getContactUsDetail(id, setIsLoading));
  }, [id, dispatch]);

  const prevProps = useRef({ refetch }).current;

  useEffect(() => {
    if (prevProps.refetch !== refetch) {
      setIsLoading(true);
      dispatch(getContactUsDetail(id, setIsLoading));
    }
    return () => {
      prevProps.refetch = refetch;
    };
  }, [dispatch, id, prevProps, refetch]);

  useEffect(() => {
    if (contactUsDetails && contactUsDetails.length > 0) {
      setData(contactUsDetails);
    } else {
      setData([]);
    }
  }, [contactUsDetails]);

  const columns = [
    {
      name: "Name",
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      sortable: true,
    },
    {
      name: "Contact Number",
      selector: (row) => row?.contactNo,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <label className={`badge badge-${row.active ? "success" : "danger"}`}>
          {row.active ? "Active" : "Inactive"}
        </label>
      ),
      sortable: true,
    },
  ];

  const filteredData = useMemo(() => {
    return data.filter((item) => {
      const name = item?.name;
      const searchTextLower = searchText.toLowerCase();

      const matchesSearchText = name.toLowerCase().includes(searchTextLower);
      const matchesStatus =
        status === "Active"
          ? item.active === true
          : status === "Inactive"
          ? item.active === false
          : true;

      return matchesSearchText && matchesStatus;
    });
  }, [data, searchText, status]);

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    noRowsPerPage: false,
    selectAllRowsItem: false,
    selectAllRowsItemText: "All",
  };

  const customPagination = () => {
    const page = Math.ceil(filteredData.length / paginationPerPage);
    const pageButtons = [];
    const startPage = Math.max(currentPage - 1, 1);
    const endPage = Math.min(currentPage + 1, page);

    // Always add the first page
    pageButtons.push(
      <button
        key={1}
        className={`btn-pagination ${currentPage === 1 ? "active" : ""}`}
        onClick={() => setCurrentPage(1)}
      >
        1
      </button>
    );

    // Ellipsis after the first page if necessary
    if (startPage > 2) {
      pageButtons.push(
        <span key="ellipsis-start" className="pagination-ellipsis">
          &hellip;
        </span>
      );
    }

    // Range of buttons around the current page
    for (let i = startPage; i <= endPage; i++) {
      if (i !== 1 && i !== page) {
        pageButtons.push(
          <button
            key={i}
            className={`btn-pagination ${currentPage === i ? "active" : ""}`}
            onClick={() => setCurrentPage(i)}
          >
            {i}
          </button>
        );
      }
    }

    // Ellipsis before the last page if necessary
    if (endPage < page - 1) {
      pageButtons.push(
        <span key="ellipsis-end" className="pagination-ellipsis">
          &hellip;
        </span>
      );
    }

    // Always add the last page
    if (page !== 1) {
      pageButtons.push(
        <button
          key={page}
          className={`btn-pagination ${currentPage === page ? "active" : ""}`}
          onClick={() => setCurrentPage(page)}
        >
          {page}
        </button>
      );
    }

    return (
      <div className="pagination-container mt-5">
        <button
          className="btn-pagination btn-pagi_prev"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {arrowRight}
        </button>
        <span>{pageButtons}</span>
        <button
          className="btn-pagination btn-pagi_next"
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === page}
        >
          {arrowRight}
        </button>
      </div>
    );
  };

  const paginationPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const paginatedData = filteredData.slice(
    (currentPage - 1) * paginationPerPage,
    currentPage * paginationPerPage
  );

  return (
    <>
      {isLoading ? (
        <div className="w-100 d-flex">
          <div
            className="spinner-border text-primary mx-auto"
            role="status"
          ></div>
        </div>
      ) : (
        <>
          <div>
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <>
                      <DataTable
                        title={
                          <h6 className="card-title">
                            Contact-Us Id : {contactUsDetails?.[0]?._id}
                          </h6>
                        }
                        columns={columns}
                        data={paginatedData}
                        paginationServer
                        paginationTotalRows={filteredData.length}
                        onChangePage={(page) => setCurrentPage(page)}
                        paginationComponentOptions={paginationOptions}
                        paginationComponent={customPagination}
                        highlightOnHover
                        pointerOnHover
                        subHeader
                        subHeaderAlign="left"
                        subHeaderComponent={<></>}
                      />

                      <div className="mt-4">
                        <h6>Message</h6>
                        <p>{contactUsDetails?.[0]?.message}</p>
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ContactUsDetails;
